<template>
  <div class="all_main_wrap">
    <!-- 动态统计报表列表 -->
    <HeaderBox>
      <template #title> 数据统计 </template>
    </HeaderBox>

    <!-- dataBack -->
    <div class="dataList">
      <div class="item" v-for="item in dataList" :key="item.id">
        <div class="introduce">
          <p class="name">{{ item.reportName }}</p>
          <p class="detail">{{ item.reportNote }}</p>
        </div>
        <p class="btn" @click="goDetail(item.reportCode)"><a-icon type="search" />查询</p>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  data() {
    return {
      dataList: []
    }
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取列表
    getData() {
      this.$ajax({
        url: '/hxclass-management/report-config/list',
         methods: 'get'
      }).then(res=>{
        if(res.code == 200 && res.success) {
          this.dataList = res.data;
        }else{
          this.$message.error(res.message);
        }
      })
    },

    // 跳转详情
    goDetail(e) {
      this.$router.push('/admin/intelligenceDetail?reportCode=' + e);
    }
  }
}
</script>

<style scoped lang="less">
  .all_main_wrap{
    background-color: #F5F9FF !important;
    box-shadow: 0px 0 6px 0px rgba(0,0,0,0.1);
  }
  .dataList{
    padding-top: 22px;
    display: flex;
    gap: 34px 28px;
    align-items: center;
    flex-wrap: wrap;
    .item{
      width: 361px;
      height: 275px;
      background: #FFFFFF;
      box-shadow: 0px 6 6px 0px rgba(20,81,171,0.18);
      border-radius: 14px;
      border: 1px solid #FFFFFF;
      padding: 14px;
      .introduce{
        height: 184px;
        background: #E9F2FF;
        border-radius: 17px 17px 0px 0px;
        padding: 22px 28px;
        .name{
          font-size: 22px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #3681EF;
          line-height: 26px;
          white-space: nowrap; 
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .detail{
          font-size: 17px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: rgba(54,129,239,0.5);
          line-height: 20px;
          margin-top: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 5; /* 控制显示的行数 */
          // max-height: 3.2em; /* 控制显示的高度 */
        }
      }
      .btn{
        width: 333px;
        height: 41px;
        border: 1px solid rgba(54,129,239,0.5);
        border-radius: 6px 6px 6px 6px;
        text-align: center;
        line-height: 41px;
        margin-top: 17px;
        font-size: 17px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        background: #FFFFFF;
        color: #3681EF;
        cursor: pointer;
      }
      .btn:hover{
        background: linear-gradient(180deg, #3681F0 0%, #2263C5 100%);
        color: #FFFFFF;
      }
    }
  }
</style>